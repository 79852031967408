import React from "react";
import PropTypes from "prop-types";
import { graphql, Link, Script } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { FluidSizedProgramLink } from "../components/FluidSizedProgram";
import BgImage from "../components/BgImage";
import SectionShader from "../components/SectionShader";
import MenuLogoPOT from "../components/MenuLogoWhitePOT";
import { Section, Title, SubTitle } from "../components/Section";
import { Seo } from "../components/Seo";
import { useSiteMetadata } from "../hooks/use-site-metadata";

// JSON-LD info
const jsonLdHomePage = ({ description }) => ({
  "@context": "http://schema.org",
  "@type": "NGO",
  address: {
    "@type": "PostalAddress",
    addressLocality: "Gothenburg, Sweden",
    postalCode: "41557",
    streetAddress: "Teleskopgatan 18",
  },
  name: "PerformOnTop",
  description: description,
  slogan: "Don't stop until you're proud!",
  email: "contact@performontop.com",
  url: "https://performontop.com",
  sameAs: [
    "https://www.facebook.com/PerformOnTop",
    "https://www.linkedin.com/company/perform-on-top",
    "https://www.youtube.com/channel/UCvMNeUD6oSPWjrxp_aUKpgw",
  ],
  taxID: "802528-2404",
  foundingDate: "2020-01-10",
  foundingLocation: "Gothenburg, Sweden",
  founder: [
    {
      "@type": "Person",
      familyName: "Tsarenko",
      givenName: "Anastasiia",
    },
    {
      "@type": "Person",
      familyName: "Sarkar",
      givenName: "Pritama",
    },
    {
      "@type": "Person",
      familyName: "Thatte",
      givenName: "Noopur",
    },
    {
      "@type": "Person",
      familyName: "Wagnerova",
      givenName: "Kristyna",
    },
    {
      "@type": "Person",
      familyName: "Shevtsov",
      givenName: "Oleksii",
    },
  ],
});

// Head function
export const Head = ({ location }) => (
  <Seo pathname={location.pathname} />
);

// Hero section
const HeroSectionLanding = ({ heroBgImg }) => (
  <header className="relative min-h-screen mob:min-h-0">
    <BgImage img={heroBgImg} altTxt="Hero background" />
    <SectionShader className="opacity-30" />
    <div className="absolute inset-0 flex flex-col items-center justify-between p-4 text-white md:p-10 sm:items-start">
      <nav className="flex items-start justify-between w-full">
        <MenuLogoPOT />
        <Link
          to="/about-us/"
          role="menuitem"
          className="block text-sm font-bold mob:text-base lg:text-lg xl:text-xl hover:underline"
        >
          About us
        </Link>
      </nav>
      <div className="w-full my-auto mob:w-10/12 sm:w-7/12 md:w-6/12 lg:w-6/12 xl:w-5/12">
        <p className="text-4xl font-bold leading-none text-center sm:text-left sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl">
          We are a community.
        </p>
        <p className="mt-4 text-center sm:mt-5 md:mt-6 sm:text-left lg:text-lg xl:text-xl">
          We are a community of ambitious like-minded people who aim to unlock
          full potential and excel in professional journey.
        </p>
        <div className="flex justify-center w-full mt-6 text-sm text-center sm:mt-8 lg:mt-12 sm:text-base">
          <Link
            to="/joinus/"
            role="button"
            className="inline-block w-6/12 px-4 py-3 bg-orange-700/70 hover:bg-orange-700/100 rounded-md"
          >
            Join our community
          </Link>
        </div>
      </div>
      <div className="text-center mob:text-sm sm:text-base grid gap-x-10 gap-y-2 sm:gap-y-4 grid-cols-1 mob:grid-cols-2 mob:text-left lg:text-lg xl:text-xl mob:ml-10 md:ml-12 lg:ml-16 lg:mb-10">
        <p>Career Growth</p>
        <p>Personal Branding</p>
        <p>Professional Networking</p>
        <p>Self-improvement</p>
        <p>Diversity & Inclusion</p>
        <p>Youth with Disabilities <span className="font-extrabold">NEW</span></p>
      </div>
    </div>
  </header>
);

HeroSectionLanding.propTypes = {
  heroBgImg: PropTypes.object.isRequired,
};

const ServicesSection = () => {
  return (
    <Section>
      <Title>Perform On Top. Don't stop until you are proud!</Title>
      <SubTitle>
        We focus on self-improvement and professional development. We learn how
        to stand out on the job market and aim to unlock our full potential. We
        seek opportunities to become better and bounce ideas with like-minded
        people.
      </SubTitle>
    </Section>
  );
};

// Our Programs section
const OurProgramsSection = ({ ourProgramsImages }) => {
  return (
    <Section>
      <Title>Our programs</Title>
      <div className="flex flex-wrap mt-8 justify-evenly">
        <FluidSizedProgramLink
          fluidImg={
            ourProgramsImages.welcomeToSweden.childImageSharp.gatsbyImageData
          }
          alt="Welcome To Sweden program"
          title="Welcome to Sweden"
          description="Job-hunting bootcamp"
          pathname="/welcome-to-sweden/"
        />
        <FluidSizedProgramLink
          fluidImg={
            ourProgramsImages.shineBright.childImageSharp.gatsbyImageData
          }
          alt="Placeholder for upcoming program"
          title="Shine bright"
          description="Mentorship, supervision, networking"
          pathname="/shine-bright/"
        />
        <FluidSizedProgramLink
          fluidImg={
            ourProgramsImages.transitionToIT.childImageSharp.gatsbyImageData
          }
          alt="Transition to IT program"
          title="Transition to IT"
          description="Change your career or upgrade your skills"
          pathname="/transition-to-it/"
        />
        <FluidSizedProgramLink
          fluidImg={
            ourProgramsImages.selfImprovement.childImageSharp.gatsbyImageData
          }
          alt="Self-improvement program"
          title="Self-improvement"
          description="Individual growth, empowerment, engagement"
          pathname="/self-improvement/"
        />
      </div>
    </Section>
  );
};

OurProgramsSection.propTypes = {
  ourProgramsImages: PropTypes.object.isRequired,
};

// Featured Project section
const FeaturedProjectSection = ({ featuredProjectsImages }) => {
  return (
    <Section>
      <Title>Featured project <span className="font-extrabold">NEW</span></Title>
      <div className="flex flex-wrap mt-8 justify-evenly">
        <FluidSizedProgramLink
          fluidImg={
            featuredProjectsImages.greenGrowthAndCulture.childImageSharp.gatsbyImageData
          }
          alt="Green growth and culture project"
          title="Green growth and culture"
          description="Designing green growth learning aids for young persons with disabilities"
          pathname="/green-growth-and-culture/"
        />
      </div>
    </Section>
  );
};

FeaturedProjectSection.propTypes = {
  featuredProjectsImages: PropTypes.object.isRequired,
};

// Members Think section
const testimonialsData = {
  preetha: {
    fullName: "Preetha Venkatraman",
    testimonial:
      "The founder Anastasiia and co-founders Pritama, Noopur, Kristyna, and Oleksii have a great vision. They are exceptionally talented and have a doer attitude. With their wonderful team, I am sure Perform On Top will reach new heights in the near future.",
  },
  erik: {
    fullName: "Erik Hajdu",
    testimonial:
      "My impression on the first meeting was that I’m surrounded by people who are in my shoes and want to achieve the same goals. The environment was really friendly and positive with all these people who want to move forward in their personal and professional life.",
  },
  akansha: {
    fullName: "Akansha Patel",
    testimonial:
      "I really like the way they deal with the things and all the instructions given by them was really helpful for us to move further and follow our dreams towards hard work.",
  },
};

const TestimonialCard = ({
  testimonialImg,
  fullName,
  testimonial,
  className,
}) => {
  return (
    <div
      className={`${className} shadow-md rounded-md p-6 lg:p-4 flex-auto mob:flex-initial lg:flex-1 mx-0 md:mx-3 w-full mob:w-9/12 sm:w-7/12 md:w-5/12 lg:w-auto text-center`}
    >
      <p className="lg:text-lg">
        <q>{testimonial}</q>
      </p>
      <p className="mt-6 font-semibold text-orange-700 lg:text-lg">
        {fullName}
      </p>
      <div className="w-5/12 mx-auto mt-4">
        <GatsbyImage
          image={testimonialImg.childImageSharp.gatsbyImageData}
          alt={`${fullName} photo`}
          className="w-full rounded-full"
        />
      </div>
    </div>
  );
};

TestimonialCard.propTypes = {
  testimonialImg: PropTypes.object.isRequired,
  fullName: PropTypes.string.isRequired,
  testimonial: PropTypes.string.isRequired,
  className: PropTypes.string,
};

const MembersThinkSection = ({ testimonialImages }) => {
  const testimonialsCards = Object.entries(
    testimonialImages
  ).map(([name, image], idx) => (
    <TestimonialCard
      key={`testimonial-card-${idx}`}
      className={`${idx > 0 ? "mt-8 lg:mt-0" : ""}`}
      testimonialImg={image}
      {...testimonialsData[name]}
    />
  ));
  return (
    <Section>
      <Title>What our members think</Title>
      <SubTitle>
        To be honest, we get energy and motivation from our fantastic members
        who understand the purpose of our community and try to help others, who
        come to us seeking support. They are the backbone of Perform On Top.
      </SubTitle>
      <div className="flex flex-wrap justify-center mt-8 lg:flex-no-wrap lg:justify-between md:items-start">
        {testimonialsCards}
      </div>
    </Section>
  );
};

MembersThinkSection.propTypes = {
  testimonialImages: PropTypes.object.isRequired,
};

// Index page
const IndexPage = ({ data }) => {
  const ourProgramsImages = {
    welcomeToSweden: data.welcomeToSwedenImg,
    shineBright: data.shineBrightImg,
    transitionToIT: data.transitionToITImg,
    selfImprovement: data.selfImprovementImg,
  };

  const featuredProjectsImages = {
    greenGrowthAndCulture: data.greenGrowthAndCultureImg,
  };

  const testimonialImages = {
    preetha: data.preethaImg,
    erik: data.erikImg,
    akansha: data.akanshaImg,
  };

  const { description } = useSiteMetadata();

  return (
    <>
      <Script type="application/ld+json">
        {JSON.stringify(jsonLdHomePage(description))}
      </Script>
      <HeroSectionLanding heroBgImg={data.heroBgImg} />
      <main>
        <ServicesSection />
        <OurProgramsSection ourProgramsImages={ourProgramsImages} />
        <FeaturedProjectSection featuredProjectsImages={featuredProjectsImages} />
        <MembersThinkSection testimonialImages={testimonialImages} />
      </main>
    </>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const indexQuery = graphql`
  query IndexQuery {
    heroBgImg: file(relativePath: { eq: "hero-bg.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    preethaImg: file(relativePath: { eq: "preetha.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    erikImg: file(relativePath: { eq: "erik.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    akanshaImg: file(relativePath: { eq: "akansha.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 200, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    welcomeToSwedenImg: file(
      relativePath: { eq: "welcome-to-sweden-hero-img.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 286, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    shineBrightImg: file(relativePath: { eq: "shine-bright-hero-img.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 286, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    transitionToITImg: file(
      relativePath: { eq: "transition-to-it-hero-img.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 286, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    selfImprovementImg: file(
      relativePath: { eq: "self-improvement-hero-img.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 286, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
    greenGrowthAndCultureImg: file(
      relativePath: { eq: "green-growth-and-culture-hero-img.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 480, height: 200, layout: CONSTRAINED, placeholder: BLURRED)
      }
    }
  }
`;

export default IndexPage;
