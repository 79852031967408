import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const FluidSizedProgramContainer = ({ fluidImg, alt, children }) => (
  <div
    className="my-4 sm:my-0 sm:p-4"
    style={{
      width: fluidImg.width,
      maxHeight: fluidImg.height,
    }}
  >
    <div className="relative">
      <GatsbyImage image={fluidImg} alt={alt} />
      <div className="absolute inset-0 bg-gray-900 opacity-50"></div>
      {children}
    </div>
  </div>
);

FluidSizedProgramContainer.propTypes = {
  fluidImg: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  children: PropTypes.node,
};

export const FluidSizedProgramTile = ({
  fluidImg,
  alt,
  title,
  description,
}) => (
  <FluidSizedProgramContainer fluidImg={fluidImg} alt={alt}>
    <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white hover:text-gray-400">
      <p className="text-2xl font-bold sm:text-3xl">
        <q>{title}</q>
      </p>
      <p className="text-lg font-bold sm:text-xl">{description}</p>
    </div>
  </FluidSizedProgramContainer>
);

FluidSizedProgramTile.propTypes = {
  fluidImg: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export const FluidSizedProgramLink = ({
  fluidImg,
  alt,
  title,
  description,
  pathname,
}) => (
  <FluidSizedProgramContainer fluidImg={fluidImg} alt={alt}>
    <Link to={pathname} role="navigation" aria-label={title}>
      <div className="p-2 absolute inset-0 flex flex-col items-center justify-center font-bold text-center text-white hover:text-gray-400">
        <p className="text-lg sm:text-xl">
          <q>{title}</q>
        </p>
        <p>{description}</p>
      </div>
    </Link>
  </FluidSizedProgramContainer>
);

FluidSizedProgramLink.propTypes = {
  fluidImg: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
};
